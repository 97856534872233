import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAllPadronNominal: (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_padron_nominal",
          type: "string"
        },
        {
          name: "@page",
          value: payload.currentPage,
          type: "int"
        }
      ]
    };

    if (payload.establecimiento !== "all") {
      const { establecimiento } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "nombre_eess_atencion",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${establecimiento}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "CONCAT(apep_pat, ' ' , apep_mat, ' ' , nombrr)|dni|cnv",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${payload.search}%|%${payload.search}%|%${payload.search}%`,
        type: "string"
      });
    } else if (payload.distrito !== "all") {
      const { distrito } = payload;

      request._petitions.push({
        name: "@filtros_AND",
        value: "distrito",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_AND",
        value: `${distrito}`,
        type: "string"
      });

      request._petitions.push({
        name: "@filtros_OR",
        value: "CONCAT(apep_pat, ' ' , apep_mat, ' ' , nombrr)|dni|cnv",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${payload.search}%|%${payload.search}%|%${payload.search}%`,
        type: "string"
      });
    } else {
      request._petitions.push({
        name: "@filtros_OR",
        value: "CONCAT(apep_pat, ' ' , apep_mat, ' ' , nombrr)|dni|cnv",
        type: "string"
      });

      request._petitions.push({
        name: "@buscar_OR",
        value: `%${payload.search}%|%${payload.search}%|%${payload.search}%`,
        type: "string"
      });
    }

    return post(API_SERVICE_QUERY, request);
  },

  getAllPadronNominalObs: (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_migr_select_padron_nominal_obs",
          type: "string"
        },
        {
          name: "@page",
          value: payload.currentPage,
          type: "int"
        },
        {
          name: "@filtros_OR",
          value: "CONCAT(apep_pat, ' ' , apep_mat, ' ' , nombrr)|dni",
          type: "string"
        },
        {
          name: "@buscar_OR",
          value: `%${payload.search}%|%${payload.search}%`,
          type: "string"
        }
      ]
    };

    console.log(request);
    return post(API_SERVICE_QUERY, request);
  }
};
