export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "DNI",
    dataIndex: "dni",
    key: "dni",
    width: 80
  },
  {
    title: "CNV",
    dataIndex: "cnv",
    key: "cnv",
    width: 100
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
    width: 190
  },
  {
    title: "Fecha Nacimiento",
    dataIndex: "fecha_nac",
    key: "fecha_nac",
    width: 120
  },
  {
    title: "Nombre Establecimiento",
    dataIndex: "nombre_eess_atencion",
    key: "nombre_eess_atencion",
    width: 150
  },
  {
    title: "Distrito",
    dataIndex: "distrito",
    key: "distrito",
    width: 120
  },
  {
    title: "Sexo",
    dataIndex: "sexo",
    key: "sexo",
    slots: { customRender: "sexo" },
    width: 50
  }
];
