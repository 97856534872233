<template>
  <a-page-header
    @back="() => $router.go(-1)"
    title="PADRÓN NOMINAL DE OBSERVADOS"
    class="pt-0 px-0"
  >
    <template #extra>
      <div class="flex flex-wrap items-center gap-3">
        <a-breadcrumb>
          <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
          <a-breadcrumb-item>Padrón nominal de observados</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:mt-0 lg:w-auto">
      <legend class="text-sm">Filtros</legend>
      <PadronNominalFiltros @reloadFetch="reloadFetch" />
    </fieldset>
  </div>

  <a-spin tip="Descargando Reporte..." :spinning="spinning">
    <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
      <a-table
        :columns="columns"
        :row-selection="rowSelection"
        :data-source="data"
        size="small"
        :custom-row="customRow"
        :pagination="false"
        rowKey="dni"
        :loading="isLoading"
        :rowClassName="
          (record, index) =>
            index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
        "
      >
        <template #rangepages="{ index }">
          <span> {{ (curretPage - 1) * 10 + index + 1 }} </span>
        </template>

        <template #sexo="{ text: sexo }">
          <span>
            <a-tag :color="sexo === '1' ? 'blue' : 'pink'">
              {{ sexo === "1" ? "Hombre" : "Mujer" }}
            </a-tag>
          </span>
        </template>
      </a-table>
    </div>
  </a-spin>

  <footer class="flex flex-wrap lg:flex-nowrap items-center w-full justify-between pt-5 border-t-2">
    <a-pagination v-model:current="curretPage" v-model:pageSize="pageSize" :total="totalDocs" />

    <div class="flex gap-4">
      <button @click="reloadFetch">
        <ReloadOutlined />
      </button>
      <p class="">Total de registros: {{ totalDocs }}</p>
    </div>
  </footer>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { columns } from "./utilsPadronNominal";
import { useStore } from "vuex";
import PadronNominalApi from "@/api/padron_nominal";
import PadronNominalFiltros from "./PadronNominalFiltros.vue";

export default {
  components: {
    PadronNominalFiltros
  },
  setup() {
    const spinning = ref(false);
    const store = useStore();
    const data = ref([]);
    const isLoading = ref(false);
    const curretPage = ref(1);
    const pageSize = ref(10);
    const totalDocs = ref(null);

    const fetchData = (currentPage = 1) => {
      isLoading.value = true;
      const search = store.getters["padron_nominal/search"];

      PadronNominalApi.getAllPadronNominalObs({ currentPage, search })
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    watch(curretPage, () => {
      fetchData(curretPage.value);
    });

    const reloadFetch = () => {
      curretPage.value = 1;
      fetchData(curretPage.value);
    };

    onMounted(() => {
      fetchData();
    });

    return {
      spinning,
      reloadFetch,
      curretPage,
      data,
      isLoading,
      pageSize,
      totalDocs,
      columns
    };
  }
};
</script>
