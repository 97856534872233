<template>
  <div class="flex">
    <a-form
      ref="formRefFilter"
      :model="formState"
      :rules="rules"
      class="flex flex-wrap gap-0 lg:gap-4"
    >
      <a-form-item label="Buscar" class="mb-0 inline-block w-full lg:w-40" name="search">
        <a-input v-model:value="formState.search" placeholder="Buscar por DNI, nombre, otros" />
      </a-form-item>

      <a-form-item label="Distrito" class="mb-0 inline-block w-full lg:w-52" name="distrito">
        <a-select v-model:value="formState.distrito">
          <a-select-option value="all">TODOS</a-select-option>
          <a-select-option v-for="(item, index) in distritos" :value="item.distrito" :key="index">
            {{ item.distrito }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item
        label="Establecimiento"
        class="mb-0 inline-block w-full lg:w-52"
        name="establecimiento"
      >
        <a-select v-model:value="formState.establecimiento">
          <a-select-option value="all">TODOS</a-select-option>
          <a-select-option
            v-for="(establecimiento, index) in establecimientos"
            :value="establecimiento.denominacion"
            :key="index"
          >
            {{ establecimiento.denominacion }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <div class="flex gap-4">
        <button @click="applyFilters" type="submit" class="text-center px-3">
          <div class="text-3xl text-blue-400">
            <SearchOutlined />
          </div>
          <p>Buscar</p>
        </button>

        <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
          <div
            class="text-3xl"
            :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
          >
            <ClearOutlined />
          </div>
          <p>Limpiar filtros</p>
        </button>
      </div>
    </a-form>
  </div>
</template>
<script>
import { reactive, ref, toRaw, onMounted } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";
import EstablecimientoApi from "@/api/establecimiento";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  emits: ["reloadFetch"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);
    const establecimientos = ref([]);
    const distritos = ref([]);

    const formState = reactive({
      search: store.getters["padron_nominal/search"],
      establecimiento: store.getters["padron_nominal/establecimiento"],
      distrito: store.getters["padron_nominal/distrito"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const getEstablecimientos = () => {
      EstablecimientoApi.getAllEstablecimientos("", "")
        .then((response) => {
          console.log(response);
          establecimientos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const getDistritos = () => {
      EstablecimientoApi.getAllDistritos({ filtro: "" })
        .then((response) => {
          // console.log("getAllDistritos", response);
          distritos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      getEstablecimientos();
      getDistritos();
    });

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { search, establecimiento, distrito } = toRaw(formState);
          store.dispatch("padron_nominal/setSearch", search);
          store.dispatch("padron_nominal/setEstablecimiento", establecimiento);
          store.dispatch("padron_nominal/setDistrito", distrito);
          emit("reloadFetch");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.search = "";
      formState.establecimiento = "all";
      formState.distrito = "all";
      store.dispatch("padron_nominal/cleanFilters");
      emit("reloadFetch");
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled,
      establecimientos,
      distritos
      // formLayout
    };
  }
};
</script>
